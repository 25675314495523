import { getDomainName } from '~/lang/utils/pulangfetch'
import { keysToCamel } from '~/utility/toCamel'
import { langFallback$Fetch } from '../../lang/utils/langfetch'

export async function getCsrfToken() {
  return keysToCamel(
    await langFallback$Fetch(
      'en-gb',
      `/accounts/ping/?domain=${getDomainName()}`,
    ),
  )
}

export async function csrfTokenService() {
  return await getCsrfToken()
}
