import { getLangFallbackUrl } from '~/lang/utils/langfetch'
import { fetchWithCache } from '~/utility/fetchWithCache'
import { FullHierarchy, Hierarchy } from '../domain/types'

interface HierarchyResponse {
  children: HierarchyResponse[]
  id: string
  name: string
  path: string
  type: number
}

interface HierarchyCountryResponse {
  id: string
  name: string
  path: string
  type: number
}

export class AjaxHierarchyReadRepository {
  private _cachedHierarchyTrees = {}

  async getFullHierarchy(
    path: string,
    langCode: string,
  ): Promise<FullHierarchy> {
    const url = this.getHierarchyFullCountryUrl(path, langCode)
    return await this.fetch<FullHierarchy>(url)
  }

  async getHierarchyWithBookableOnly(
    path: string,
    langCode: string,
  ): Promise<FullHierarchy> {
    const url = this.getHierarchyFullCountryUrlBookableOnly(path, langCode)
    const cacheKey = `getHierarchyWithBookableOnly-${
      path.split('/', 1)[0]
    }-${langCode}`
    return await fetchWithCache<FullHierarchy>({ url, cacheKey })
  }

  async getAncestors(path: string, langCode: string): Promise<Hierarchy[]> {
    const pathChunks = this.getPathChunks(path)
    if (pathChunks.length < 2) return []
    const countryPath = `${pathChunks[0]}/`
    const url = this.getHierarchyFullCountryUrl(countryPath, langCode)
    const fetchedData = await this.fetch<HierarchyResponse>(url)
    const hierarchies: Hierarchy[] = [
      {
        id: fetchedData.id,
        name: fetchedData.name,
        path: fetchedData.path,
        type: fetchedData.type,
      },
    ]
    const directChildren = this.getDirectChildren(path, fetchedData.children)
    const directChildrenWithCurrentExcluded =
      directChildren.length &&
      directChildren[directChildren.length - 1].path === path
        ? directChildren.slice(0, -1)
        : directChildren
    return [...hierarchies, ...directChildrenWithCurrentExcluded]
  }

  async getChildren(path: string, langCode: string): Promise<Hierarchy[]> {
    const isRootPath = path === '/'
    return (await isRootPath)
      ? this.getCountries(langCode)
      : this.getSearchedCountries(path, langCode)
  }

  async getCountries(langCode: string): Promise<Hierarchy[]> {
    const url = this.getHierarchyCountriesUrl(langCode)
    const response = await fetchWithCache<
    HierarchyCountryResponse[] | undefined
    >({ url })
    if (!response) return []
    return response.map((hierarchy) => ({
      ...hierarchy,
      id: hierarchy.id,
    }))
  }

  async getSearchedCountries(
    path: string,
    langCode: string,
  ): Promise<Hierarchy[]> {
    const url = this.getHierarchyFullCountryUrl(path, langCode)
    const response = await this.fetch<HierarchyResponse>(url)
    const children =
      this.getPathChunks(path).length > 1 && response.children
        ? response.children[0].children
        : response.children || []
    return children.map((hierarchy) => ({
      id: hierarchy.id,
      name: hierarchy.name,
      path: hierarchy.path,
      type: hierarchy.type,
    }))
  }

  async readOneByPath(path: string, langCode: string): Promise<Hierarchy> {
    const url = this.getHierarchyFullCountryUrl(path, langCode)
    const response = await this.fetch<HierarchyResponse>(url)
    const hierarchy =
      this.getPathChunks(path).length > 1 && response.children
        ? response.children[0]
        : response
    // remove children from hierarchy as otherwise js too large
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, ...data } = hierarchy
    return data
  }

  private getDirectChildren(
    path: string,
    nodeChildren: HierarchyResponse[],
  ): Hierarchy[] {
    const hierarchies: Hierarchy[] = []

    function populateHierarchies(children: HierarchyResponse[]) {
      children = children || []
      for (const hierarchy of children) {
        if (path.startsWith(hierarchy.path)) {
          hierarchies.push({
            id: hierarchy.id,
            name: hierarchy.name,
            path: hierarchy.path,
            type: hierarchy.type,
          })
          populateHierarchies(hierarchy.children)
        }
      }
    }

    populateHierarchies(nodeChildren)
    return hierarchies
  }

  private getPathChunks(path: string) {
    return path.split('/').filter((element) => !!element)
  }

  private getHierarchyCountriesUrl(langCode: string) {
    return getLangFallbackUrl(langCode, '/_/hierarchy/countries/')
  }

  private getHierarchyFullCountryUrl(countryPath: string, langCode: string) {
    return getLangFallbackUrl(
      langCode,
      `/_/hierarchy/full-country/?path=${countryPath}`,
    )
  }

  private getHierarchyFullCountryUrlBookableOnly(
    countryPath: string,
    langCode: string,
  ) {
    return getLangFallbackUrl(
      langCode,
      `/_/hierarchy/full-country/?path=${countryPath}&bookable_only=1`,
    )
  }

  private async fetch<T>(url: string): Promise<T> {
    if (url && this._cachedHierarchyTrees[url]) {
      return await this._cachedHierarchyTrees[url]
    }
    const result = $fetch<T>(url)
    if (url) {
      this._cachedHierarchyTrees[url] = result
    }
    return await result as T
  }
}
