import { getHierarchyRepository } from '../../inversify.config'
import { Hierarchy, HierarchyTree } from '../types'

export class HierarchyTreeForPath {
  async execute(path: string, langCode: string) {
    path = this.fixPath(path)
    const [baseTreeElements, childrenTree] = await Promise.all([
      this.getBaseTreeElements(path, langCode),
      this.getChildrenTree(path, langCode),
    ])
    const baseTree = this.getBaseTree(baseTreeElements)
    return this.getFinalTree(baseTree, childrenTree)
  }

  private fixPath(path: string) {
    return path === '' ? '/' : path
  }

  private getFinalTree(
    baseTree: HierarchyTree,
    childrenTree?: HierarchyTree[],
  ) {
    return childrenTree
      ? this.attachChildrenToLeaf(baseTree, childrenTree)
      : baseTree
  }

  private async getBaseTreeElements(path: string, langCode: string) {
    const [baseTreeElements, current] = await Promise.all([
      this.getAncestors(path, langCode),
      this.getCurrentHierarchy(path, langCode),
    ])
    if (current) {
      baseTreeElements.push(current)
    }
    return baseTreeElements
  }

  private async getAncestors(path: string, langCode: string) {
    const ancestors = await getHierarchyRepository().getAncestors(path, langCode)
    ancestors.unshift({ id: '', name: '', path: '/', type: -1 })
    return ancestors
  }

  private getCurrentHierarchy(path: string, langCode: string) {
    return path === '/'
      ? undefined
      : getHierarchyRepository().readOneByPath(path, langCode)
  }

  private getBaseTree(hierarchies: Hierarchy[]): HierarchyTree {
    return {
      label: hierarchies[0].name,
      path: hierarchies[0].path,
      children:
        hierarchies.length > 1 ? [this.getBaseTree(hierarchies.slice(1))] : [],
    }
  }

  private async getChildrenTree(path: string, langCode: string) {
    const hierarchies = await getHierarchyRepository().getChildren(path, langCode)
    return hierarchies.length
      ? hierarchies.map((hierarchy) => ({
        children: [],
        label: hierarchy.name,
        path: hierarchy.path,
      }))
      : undefined
  }

  private attachChildrenToLeaf(
    baseTree: HierarchyTree,
    children: HierarchyTree[],
  ) {
    const node = { ...baseTree }
    const leaf = this.getLeaf(node)
    leaf.children = children
    return node
  }

  private getLeaf(node: HierarchyTree): HierarchyTree {
    return node.children.length ? this.getLeaf(node.children[0]) : node
  }
}
