import { assign, createMachine, interpret, Interpreter, spawn } from 'xstate'
import isNotAnonAxiosError from '~~/utility/isNotAnonAxiosError'
import sentryCapture from '~~/utility/sentryCapture'
import { assertEventType } from '~~/utility/xstate/assertEvent'
import { createFavouriteMachine } from './favourite.machine'
import { FavouriteRepositoryAjax } from './FavouriteRepositoryFetch'
import { FavouritesFetch } from './FavouritesFetch'
import { FavouriteCampsiteDetails } from './types'

const RETRIES = 3

type Events =
  | { type: 'GET_BUTTON'; id: string }
  | { type: 'REMOVE_BUTTON'; id: string }
  | { type: 'CLEAR_ALL' }
  | { type: 'FETCH_ALL'; data: string[] }
  | { type: 'FAVOURITE_ADD'; id: string }
  | { type: 'FAVOURITE_REMOVE'; id: string }
  | { type: 'CLEAR_ALL_DETAILS' }
  | {
    type: 'FETCH_ALL_DETAILS'
    data: { page: number; numPages: number; results: any[] } // TODO: feed it with new type
  }

export interface FavouritesCampsiteMachineContext {
  favouritesDetails: Record<string, FavouriteCampsiteDetails>
  favourites: Array<{ id: string; added: Date }>
  fetching: {
    page: number
    retries: number
  }
  currentLanguage: string
  activeFavourites: {
    id: string
    ref: any
  }[]
}

export function getInitialContext(): FavouritesCampsiteMachineContext {
  return {
    favourites: [],
    favouritesDetails: {},
    fetching: {
      page: 1,
      retries: 0,
    },
    currentLanguage: 'en-gb',
    activeFavourites: [],
  }
}

export const createFavouritesMachine = ({
  currentLanguage,
}: {
  currentLanguage: string
}) =>
  createMachine(
    {
      id: 'favourites',
      initial: 'fetchingFavourites',
      context: {
        ...getInitialContext(),
        currentLanguage,
      },
      schema: {
        events: {} as Events,
        context: {} as FavouritesCampsiteMachineContext,
      },
      type: 'parallel',
      states: {
        handlingButtons: {
          on: {
            GET_BUTTON: 'handlingButtons.addingButton',
            REMOVE_BUTTON: 'handlingButtons.removingButton',
            FAVOURITE_ADD: { actions: 'addFavourite' },
            FAVOURITE_REMOVE: { actions: 'removeFavourite' },
          },
          initial: 'idle',
          states: {
            idle: {},
            addingButton: {
              entry: 'assignNewFavouriteButtonMachine',
              always: 'idle',
            },
            removingButton: {
              entry: 'clearButtonMachine',
              always: 'idle',
            },
          },
        },
        fetchingFavourites: {
          initial: 'fetchingAll',
          on: {
            FETCH_ALL: '.fetchingAll',
            CLEAR_ALL: {
              target: ['fetchingFavouritesDetails.showing.empty', 'fetchingFavouritesDetails.fetching.idle'],
              actions: 'clearAllFavourites',
            },
          },
          states: {
            idle: {},
            fetchingAll: {
              invoke: {
                src: 'fetchFavouritesService',
                onDone: {
                  target: 'idle',
                  actions: ['assignFavourites', 'updateChildButtons'],
                },
                onError: {
                  target: 'idle',
                  actions: 'logError',
                },
              },
            },
          },
        },
        fetchingFavouritesDetails: {
          type: 'parallel',
          on: {
            FETCH_ALL_DETAILS: '.fetching.processing',
            CLEAR_ALL_DETAILS: {
              target: '.showing.empty',
              actions: 'clearAllFavouritesDetails',
            },
          },
          states: {
            showing: {
              initial: 'empty',
              states: {
                empty: {},
                results: {},
                error: {},
              },
            },
            fetching: {
              initial: 'idle',
              states: {
                idle: {},
                processing: {
                  invoke: {
                    src: 'fetchDetailsBatch',
                    onDone: [
                      {
                        actions: ['setFetchingData', 'addFavouritesDetails'],
                        target: ['processing', '#favourites.fetchingFavouritesDetails.showing.results'],
                        cond: 'hasNextPageToFetch',
                      },
                      {
                        actions: 'addFavouritesDetails',
                        target: ['idle', '#favourites.fetchingFavouritesDetails.showing.results'],
                        cond: 'favouritesDetailsExists',
                      },
                      {
                        target: ['idle', '#favourites.fetchingFavouritesDetails.showing.empty'],
                      },
                    ],
                    onError: {
                      target: 'retrying',
                      actions: 'logError',
                    },
                  },
                  exit: 'resetFetchingInfo',
                },
                retrying: {
                  always: [
                    {
                      target: 'processing',
                      actions: 'riseFetchingIteration',
                      cond: ({ fetching }) => fetching.retries < RETRIES,
                    },
                    {
                      target: '#favourites.fetchingFavouritesDetails.showing.error',
                    },
                  ],
                },
              },
            },
          },
        },
      },
    },
    {
      guards: {
        hasNextPageToFetch: (_, { data }: any) => {
          return data.page < data.numPages
        },
        favouritesDetailsExists: (_, { data }: any) => {
          return data.results.length
        },
      },
      actions: {
        resetFetchingInfo: assign({
          fetching: (_) => getInitialContext()['fetching'],
        }),
        riseFetchingIteration: assign({
          fetching: ({ fetching }) => ({
            ...fetching,
            retries: ++fetching.retries,
          }),
        }),
        clearButtonMachine: assign({
          activeFavourites: ({ activeFavourites }, event: any) => {
            const buttons = { ...activeFavourites }
            delete buttons[event.id]
            return buttons
          },
        }),
        addFavourite: assign({
          favourites: (context, event) => {
            assertEventType(event, 'FAVOURITE_ADD')
            const newFavourites = context.favourites
            newFavourites.push({
              id: event.id,
              added: new Date(),
            })
            return newFavourites
          },
        }),
        removeFavourite: assign({
          favourites: (context, event) => {
            assertEventType(event, 'FAVOURITE_REMOVE')
            return (
              context.favourites.filter((fav) => fav.id !== event.id) ||
              context.favourites
            )
          },
          favouritesDetails: (context, event) => {
            assertEventType(event, 'FAVOURITE_REMOVE')
            const favDetails = context.favouritesDetails
            delete favDetails[event.id]
            return favDetails
          },
        }),
        assignFavourites: assign({
          favourites: (_, event: any) => {
            return event.data.map(
              (fav: { campsiteId: string; added: Date }) => ({
                id: fav.campsiteId,
                added: fav.added ? fav.added : '',
              }),
            )
          },
        }),
        setFetchingData: assign({
          fetching: (context, evt: any) => {
            return {
              ...context.fetching,
              page: evt.data.page + 1,
            }
          },
        }),
        assignNewFavouriteButtonMachine: assign({
          activeFavourites: (context, event) => {
            const favs = context.activeFavourites || {}
            assertEventType(event, 'GET_BUTTON')
            const favourite = context.favourites.find((f) => f.id === event.id)
            favs[event.id] = {
              ...event,
              ref: spawn(
                createFavouriteMachine({
                  id: event.id,
                  isFavourite: !!favourite,
                  added: favourite?.added,
                }),
              ),
            }
            return favs
          },
        }),
        clearAllFavourites: assign({
          favourites: (_) => [],
          favouritesDetails: (_) => ({}),
        }),
        clearAllFavouritesDetails: assign({
          favouritesDetails: (_) => ({}),
        }),
        addFavouritesDetails: assign<FavouritesCampsiteMachineContext, any>({
          favouritesDetails: ({ favourites, favouritesDetails }, { data }) => {
            return data.results.reduce((result, item) => {
              const favourite = favourites.find((f) => f.id === item.id)
              result[item.id] = {
                ...item,
                added: favourite ? favourite.added : '',
              }
              return result
            }, favouritesDetails)
          },
        }),
        logError: (context, event) => logError(context, event),
        updateChildButtons: (context) => {
          context.favourites.forEach((f) => {
            const button = context.activeFavourites[f.id]
            if (button) button.ref.send('SET_FAVOURITE')
          })
        },
      },
      services: {
        fetchDetailsBatch: async ({ fetching, currentLanguage }) => {
          const results = await new FavouritesFetch().fetch(
            currentLanguage,
            fetching.page,
          )
          return results
        },
        fetchFavouritesService: async ({ currentLanguage }) => {
          return await new FavouriteRepositoryAjax().readAll(currentLanguage)
        },
      },
    },
  )

export type FavouritesMachineInterpreter = Interpreter<
FavouritesCampsiteMachineContext,
any,
Events
>

export function getFavouritesNewMachine(languageCode: string) {
  return interpret(
    createFavouritesMachine({ currentLanguage: languageCode }),
  ).start()
}
let favMachine: ReturnType<typeof getFavouritesNewMachine>

// get the favourites machine as a singleton
export function getFavouritesMachine(languageCode: string) {
  if (process.server) {
    throw Error('Trying to getFavouritesMachine singleton on server')
  }

  if (favMachine) {
    return favMachine
  }
  favMachine = getFavouritesNewMachine(languageCode)
  return favMachine
}

const logError = (_, event: any) => {
  if (!event || !event.data || !event.data.response) return
  const response = event.data.response
  if (response && (response.status === 403 || response.statusCode === 403))
    return
  // aborted connection, happens only for bytespider bot
  if (event.code === 'ECONNABORTED') return
  if (isNotAnonAxiosError(event.data)) {
    event.data.name = `favouritesMachine ${event.type} error - ${
      event.code ? event.code : ''
    }`
    sentryCapture(event.data)
  }
}
