
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { FacetCount } from '~/apps/facets/domain/FacetCounts'
import { BaseCheckboxProps } from '~/components/base/BaseCheckboxProps'
import { CampsiteCategory } from '~/utility/types/CampsiteCategory'
import { getCampsiteCategoriesPresenter } from '../presenter-json/CampsiteCategoriesUpdaterJson'

interface Group {
  name: string
  categories: CampsiteCategory[]
}

@Component<CategoriesSelector>({
  head() {
    return {
      bodyAttrs: {
        class: this.isSearchForm && !this.$isDesktop ? 'noscroll' : '',
      },
    }
  },
})
export default class CategoriesSelector extends Vue {
  @Prop({ default: false, type: Boolean })
    isSearchForm: boolean

  @Prop({ required: false })
    categoryFacetCounts?: FacetCount

  @Prop({ required: true, type: Array })
    categoryIds: string[]

  @Prop({ required: false })
    allowedCategoryIds?: string[]

  get categoryGroups(): Group[] {
    const categories = getCampsiteCategoriesPresenter(
      this.$route.params.lang || 'en-gb',
    ).list
    return [
      {
        name: this.$tc('Bring my own'),
        categories: categories.filter((c) => c.isByo),
      },
      {
        name: this.$tc('Onsite accommodation'),
        categories: categories.filter((c) => !c.isByo),
      },
    ]
  }

  onToggle(id: string, checked: boolean) {
    id = id.toString()
    if (checked) this.selectCategory(id)
    else this.unselectCategory(id)
  }

  selectCategory(id: string) {
    this.submitCategories([...this.categoryIds, id])
  }

  unselectCategory(id: string) {
    this.submitCategories(this.categoryIds.filter((catId) => catId !== id))
  }

  submitCategories(categoryIds: string[]) {
    this.$emit('changeCategoryIds', categoryIds)
  }

  getCheckboxPropsForCategory(category: CampsiteCategory): BaseCheckboxProps {
    return {
      name: category.slug,
      value: this.categoryIds.includes(category.id),
    }
  }

  getCategoryCount(slug: string): number {
    return this.categoryFacetCounts ? this.categoryFacetCounts[slug] || 0 : 0
  }

  isEnabled(category: CampsiteCategory) {
    if (this.categoryIds.includes(category.id)) return true
    return this.isAllowed(category.id) && this.isAllowedByCount(category.slug)
  }

  isAllowedByCount(slug: string): boolean {
    if (!this.categoryFacetCounts) return true
    return this.categoryFacetCounts[slug] > 0
  }

  isAllowed(categoryId: string): boolean {
    if (!this.allowedCategoryIds) return true
    return this.allowedCategoryIds.includes(categoryId)
  }
}
