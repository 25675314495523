import { Component, Vue } from '~/utility/pu-class-decorator'
import { useAppMenuStore } from '~/stores/useAppMenuStore'

@Component
export class AppMenuMixin extends Vue {
  appMenu: ReturnType<typeof useAppMenuStore> | null = null
  appMenuState: string[] = []

  get appMenuVisible() {
    return this.appMenu?.states.includes('appMenuVisible')
  }

  get accountMenuVisible() {
    return this.appMenu?.states.includes('accountMenuVisible')
  }

  showAppMenu() {
    this.appMenu?.send('SHOW_APP_MENU')
  }

  showAccountMenu() {
    this.appMenu?.send('SHOW_ACCOUNT_MENU')
  }

  hideMenu() {
    this.appMenu?.send('HIDE_MENU')
  }

  toggleAccountMenu() {
    this.accountMenuVisible
      ? this.appMenu?.send('HIDE_MENU')
      : this.appMenu?.send('SHOW_ACCOUNT_MENU')
  }

  toggleAppMenu() {
    this.appMenuVisible
      ? this.appMenu?.send('HIDE_MENU')
      : this.appMenu?.send('SHOW_APP_MENU')
  }

  async mounted() {
    this.appMenu = useAppMenuStore()
    this.appMenuState = this.appMenu.states
    await this.$nextTick()
    await this.appMenuMounted()
  }

  async appMenuMounted() {
    // empty hook
  }
}
