import { createMachine, Interpreter, assign, sendParent } from 'xstate'
import { FavouriteRepositoryAjax } from './FavouriteRepositoryFetch'

type Events =
  | {
    type: 'RESET'
  }
  | {
    type: 'ADD'
    id: string
  }
  | {
    type: 'REMOVE'
    id: string
  }
  | { type: 'UPDATE_STATUS' }
  | { type: 'SET_FAVOURITE' }

const RETRIES = 3

export interface FavouriteCampsiteMachineContext {
  id?: string
  added?: Date
  addFavouriteRepeats: number
  isFavourite: boolean
}

export const createFavouriteMachine = ({
  id,
  isFavourite,
  added,
}: {
  id: string
  isFavourite: boolean
  added?: Date
}) =>
  createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QDMCGA3A9gVwE4EsAXMAYVQFsAHWIsAOlkNUO1juTEIGMALfAOygBBADYiAxBEz96ArAGt6aLHlpkqNYgyYs2Hbn0GiRCOZi7N80gNoAGALp37iUJUyar-FyAAeiAEwAzACsdP62AOyBkQCcACz+UYEANCAAnogRcXF0CeFZgXHBAIwAbOUAvhWpyjgExOrUtNrMrOycvALCYpLSsvwKShh1ahRNWoyteh2G3SZmFoSeTtbFzkggbh7S3n4IgTEAHHQR-ofF-iVXpTHFwakZCDG2gXRHRQVFZZXVILWqDTGmnok107QMXWMvRkdDMinYwwBpCBzVBbX0nSMYlMA3MlhsDms-nWrncRE8u0QB2Op3OlzudxudweiGKtlsMTowUORUOtmyh1Kh38-iqNUR9WRGlROnRM0hPTAuFwmFwdEoImYyFV5ARKkljWBLTBGNmxhxWEWy0JDm8W3JOw2e1OLIQhxinL5hxCFwiEVKtlFv3+BpRWkwlCV+P4bHwEBEYHEQgAIsmnHayUtHaA9r66IFLnE+cFSnEA6V7ulWVFQpc+XEoh7A4FAmK-hLRtLw5HcNHY-HEwAlACiAFkAPIANWH6Y29qzXidrMS+cLxdL5crj0ZpTC-nKhwiFwrpRFbZDnfG9AjUYXbFQEAgXWh-UGepGgK7157fboD6fggWniC4rLac6ZhSS4IEEnIRIeMSlMUxRwfuAYRK6yGHKENzBMERwhCUMSXOeHafledA3r2d5-o+z5KiqaoalqOrvkihrNJRv7-l0QFWgSjhgaS2yLjmVKlBEJzwYKQpskcGGITk-h3MEBa3IE-pBuK+qXkanHUdxgjiPRqrqpqhDarguoXmRuk-vptGAQs0YrGsGbCZSCB5gWwRFrYJZlrYFbyWUdDlAkgReokgTFK2wakVK5F6Z4bC4GA5CYOgz5SDCcJDNpNkcXZyV0Kl6WZY5uJ8fwoEkpsEHZr4ATISc6kIf6kRBCeGHZBJpQFoecFsokgYkflCW2bexWlRldHKiZTHmSx1njYVk3SClaUzRVlrOTatXzpBonQcUnKBURxTCkppxxMUGGqXQLzPEKcQxAcKFVL8-CYBAcDeMt7HEG5DoiY1CAALRHA9p6BmWJ0xBE-IxPJ-ihYkwQRCU0WRNFESjR+K0TLK8Dge5UFg0WrqXLY+aBtyWEipEMW43FY0AyCROwgOQMLh5HqcoWPmBgGRwvK6LYSfDGM+RcbKFLFWn42zxptHGCZ0GllCEI8QnAx5bINmEPmC-uHJ8ikVYIHh1N+e6gpRGUxQNnjbFhuzUyc2rqWwNgIiEMTOs81B+sSQLcRC6bosWwk2HBJc4klOE3nO6GX7K2wxm4Nzh2g7YGEcq8N1lujtjuhdmntqzrtp+CmJzFnDW5uyoUvBcVyMmproehJhcVgjpfnDEyc6atVHJfXIN7GDwTW9D-iw13iMYXPxT5qWfklIGRxDwV3ZrTGHtgOPHlgyjgXhHPiEL0W5R3X6oUIUK-rekUJfbwT357-eDlQEfUEHDkDZBSM2yC-IoGEjwr2FBdCKpwVI3VKG-JWSV1olU2uVH+JNdZ-3iLkOCaFHbZGnmAi2jtEJ0GDqWGKQot4fSAA */
      id: 'favouriteCampsite',
      context: {
        id,
        added,
        isFavourite,
        addFavouriteRepeats: 0,
      },
      schema: {
        context: {} as FavouriteCampsiteMachineContext,
        events: {} as Events,
      },
      initial: 'checkingFavourite',
      on: {
        SET_FAVOURITE: {
          actions: 'setFavouriteTrue',
          target: 'checkingFavourite',
        },
      },
      states: {
        checkingFavourite: {
          always: [
            {
              target: 'showingFavourite',
              cond: (ctx: any) => ctx.isFavourite,
            },
            {
              target: 'showingNotFavourite',
              cond: (ctx: any) => !ctx.isFavourite,
            },
          ],
        },
        showingFavourite: {
          on: {
            REMOVE: 'removingFavourite',
          },
        },
        showingNotFavourite: {
          on: {
            ADD: 'addingFavourite',
          },
        },
        addingFavourite: {
          invoke: {
            src: 'add',
            onDone: {
              target: 'showingFavourite',
              actions: sendParent((context: any) => ({
                type: 'FAVOURITE_ADD',
                id: context.id,
              })),
            },
            onError: [
              {
                target: 'addingFavourite',
                cond: (context: any) => context.addFavouriteRepeats! > 0,
                actions: (context: any) => {
                  context.addFavouriteRepeats!--
                },
              },
              {
                target: 'showingNotFavourite',
                actions: [
                  'logError',
                  (context: any) => {
                    context.addFavouriteRepeats = RETRIES
                  },
                ],
              },
            ],
          },
        },
        removingFavourite: {
          invoke: {
            src: 'remove',
            onDone: {
              target: 'showingNotFavourite',
              actions: sendParent((context: any) => ({
                type: 'FAVOURITE_REMOVE',
                id: context.id,
              })),
            },
            onError: {
              target: 'showingFavourite', //might be better to trigger some message
              actions: ['logError'],
            },
          },
        },
      },
    },
    {
      actions: {
        setFavouriteTrue: assign({
          isFavourite: (_) => {
            return true
          },
        }),
        logError: () => {
          throw new Error('favouritesMachine logError not implemented')
        },
      },
      services: {
        add: async (_, evt) => {
          if (evt.type === 'ADD') {
            const repo = new FavouriteRepositoryAjax()
            const response = await repo.create(evt.id, 'en-gb')
            return { id: response.campsiteId, added: response.added }
          }
          return
        },
        remove: async (_, evt) => {
          if (evt.type === 'REMOVE') {
            const response = new FavouriteRepositoryAjax()
            await response.delete(evt.id, 'en-gb')
            return evt.id
          }
          return
        },
      },
    },
  )

export type FavouriteMachineInterpreter = Interpreter<
FavouriteCampsiteMachineContext,
any,
Events
>
