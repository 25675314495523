import { getDomainName, langFallback$Fetch } from '~/lang/utils/langfetch'
import { csrfTokenService } from '../csrf/CsrfTokenService'

export class FavouriteRepositoryAjax {
  private eldarionHeaders = {
    'X-ELDARION-AJAX': 'true',
    'X-Requested-With': 'XMLHttpRequest',
  }

  async readAll(langCode: string): Promise<CampsiteFavourite[]> {
    const results: any = await langFallback$Fetch(
      langCode,
      '/_/get_all_favourites/',
      {
        headers: this.eldarionHeaders,
      },
    )
    const rawData = JSON.parse(results.alfred?.favourites_dated || '{}')
    return Object.entries(rawData).map((data) => ({
      added: data[1] ? new Date(data[1] as string).toISOString() : null,
      campsiteId: data[0],
    }))
  }

  async create(
    campsiteId: string,
    langCode: string,
  ): Promise<CampsiteFavourite> {
    await this.doFetch(campsiteId, langCode)
    return {
      added: new Date().toISOString(),
      campsiteId,
    }
  }

  async delete(campsiteId: string, langCode: string) {
    return await this.doFetch(campsiteId, langCode)
  }

  private getUrl(campsiteId: string) {
    return `/_/togglefavourite/${campsiteId}/?domain=${getDomainName()}`
  }

  private async doFetch(campsiteId: string, langCode: string) {
    const { csrfToken } = await csrfTokenService()
    return await langFallback$Fetch(langCode, this.getUrl(campsiteId), {
      method: 'POST',
      headers: {
        ...this.eldarionHeaders,
        'X-CSRFToken': csrfToken,
      },
    })
  }
}

export interface CampsiteFavourite {
  campsiteId: string
  added: string | null
}
