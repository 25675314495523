import { createMachine, interpret, Interpreter } from 'xstate'

interface Schema {
  states: {
    appMenu: object
    accountMenu: object
    hidden: object
  }
}

export function getAppMenuStateMachine() {
  return createMachine<Schema>({
    id: 'appMenu',
    initial: 'hidden',
    states: {
      appMenuVisible: {},
      accountMenuVisible: {},
      hidden: {},
    },
    on: {
      SHOW_APP_MENU: 'appMenuVisible',
      SHOW_ACCOUNT_MENU: 'accountMenuVisible',
      HIDE_MENU: 'hidden',
    },
  })
}

export type AppMenuMachineInterpreter = Interpreter<Schema>
