import { langFallback$Fetch } from '~/lang/utils/langfetch'
import { getTransformObject } from '~/shared/photos/transforms'
import { getGeoPointFromWKTPoint } from '~/utility/geo/GeoLocation'
import { FavouriteCampsiteDetails } from './types'

export class FavouritesFetch {
  async fetch(
    langCode: string,
    page?: number,
    limit?: number,
  ): Promise<FavouriteCampsiteDetails[]> {
    const result: any = await langFallback$Fetch(
      langCode,
      `/_/favourite-campsite-details/${this.getUrlParams(page, limit)}`,
    )
    return {
      ...result,
      results: this.mapResults(result.results),
    }
  }

  private mapResults(results: any): FavouriteCampsiteDetails[] {
    return results.map((campsite: any) => {
      return {
        ...campsite,
        id: campsite.id.toString(),
        status: campsite.bookable ? 'bookable' : 'free',
        nutshells: campsite.nutshells || [],
        point: campsite.point
          ? getGeoPointFromWKTPoint(campsite.point)
          : undefined,
        primaryPhoto: campsite.primaryPhoto
          ? this.mapPhoto(campsite.primaryPhoto)
          : undefined,
        leadPriceAdults:
          campsite.leadPricePersonsIncluded ||
          Number(process.env.defaultAdultsCount),
      }
    })
  }

  private getUrlParams(page?: number, limit?: number): string {
    const params: string[] = []
    if (page) {
      params.push(`page=${page.toString()}`)
    }
    if (limit) {
      params.push(`limit=${limit.toString()}`)
    }
    return params ? `?${params.join('&')}` : ''
  }

  private mapPhoto(photo: PhotoResponse) {
    return {
      caption: photo.caption || '',
      url: getTransformObject('favourite', photo.url.master_image),
    }
  }
}

interface PhotoResponse {
  caption?: string
  url: {
    master_image: string
  }
}
